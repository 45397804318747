import { Component, ViewEncapsulation } from '@angular/core'

@Component({
    selector: 'form-floating',
    template: `
        <div class="form-group mb-3">
            <div class="input-group">
                <div class="form-floating flex-grow-1">
                    <ng-content select="select" />
                    <ng-content select="input" />
                    <ng-content select="textarea" />
                    <ng-content select="label" />
                    <ng-content select=".invalid-feedback" />
                </div>
                <ng-content />
            </div>
            <small class="form-text text-muted">
                <ng-content select="[help-text]" />
            </small>
            <small class="form-text text-danger text-right">
                <ng-content select="[help-text-danger]" />
            </small>
            <small class="form-text text-muted text-right">
                <ng-content select="[help-text-muted]" />
            </small>
        </div>
    `,
    encapsulation: ViewEncapsulation.None,
    standalone: true,
})
export class FormFloatingComponent {}
