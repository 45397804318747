const ease = k => {
  return 0.5 * (1 - Math.cos(Math.PI * k));
};
/* eslint-disable */
export function now() {
  var _a;
  let fn;
  if ((_a = window.performance) === null || _a === void 0 ? void 0 : _a.now) {
    fn = () => window.performance.now();
  } else {
    fn = () => window.Date.now();
  }
  // @ts-ignore
  now = fn;
  return fn();
}
/* eslint-enable */
const DURATION = 500;
export const step = context => {
  const currentTime = now();
  const elapsed = (currentTime - context.timeStamp) / (context.duration || DURATION);
  if (elapsed > 1) {
    context.method(context.targetX, context.targetY);
    context.callback();
    return;
  }
  const value = (context.timingFunc || ease)(elapsed);
  const currentX = context.startX + (context.targetX - context.startX) * value;
  const currentY = context.startY + (context.targetY - context.startY) * value;
  context.method(currentX, currentY);
  context.rafId = window.requestAnimationFrame(() => {
    step(context);
  });
};
