// eslint-disable-next-line @typescript-eslint/no-var-requires
const packageJson = require('../../package.json')

export const environment = {
    production: true,
    package: {
        version: packageJson.version,
        name: packageJson.name,
    },
    sentry: {
        dsn: {
            'wl-iaf': 'https://3745e4ebc8a9438aa0afd8175593d017@o253450.ingest.sentry.io/1446321',
        },
    },
    captcha: {
        active: false,
    },
}
