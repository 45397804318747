import dayjs from 'dayjs'

export function calculateStartMargin(start) {
    const unixMoment = dayjs(+start.unix)
    const weekend = [0, 6].indexOf(unixMoment.day()) !== -1
    const startingHour = +unixMoment.format('H')

    // in the weekend startMargin is 0 between 00:00-11:59, 1 between 12:00-13:59 and 2 otherwise
    if (weekend) {
        if (0 <= startingHour && startingHour < 12) return { hours: 0 }
        else if (12 <= startingHour && startingHour < 14) return { hours: 1 }
    }
    // on weekdays startMargin is 0 between 00:00-10:59, 1 between 11:00-13:59 and 2 otherwise
    else {
        if (0 <= startingHour && startingHour < 11) return { hours: 0 }
        else if (11 <= startingHour && startingHour < 14) return { hours: 1 }
    }

    // Otherwise return
    return { hours: 2 }
}
