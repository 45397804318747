// 1.0.0 FA6 Kits
// 1.0.1 Added placement option
import { Component, Input } from '@angular/core'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { SizeProp } from '@fortawesome/fontawesome-svg-core'
import { TooltipModule } from 'ngx-bootstrap/tooltip'

@Component({
    selector: 'information-icon',
    template: `
        <ng-template #tooltipTemplate><ng-content></ng-content></ng-template>
        <fa-icon
            style="cursor: pointer"
            [class.float-end]="floatRight"
            [tooltip]="tooltipTemplate"
            [isOpen]="isOpen"
            [placement]="placement"
            [size]="size"
            [icon]="['fal', 'info-circle']"
            [fixedWidth]="true"
        ></fa-icon>
    `,
    standalone: true,
    imports: [TooltipModule, FontAwesomeModule],
})
export class InformationIconComponent {
    @Input() floatRight = false
    @Input() isOpen = false
    @Input() placement: any = 'auto'
    @Input() size: SizeProp
}
