import { Directive, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core'
import { I18nService } from 'app/_services/i18n.service'
import { Subscription } from 'rxjs'
import { filter, tap } from 'rxjs/operators'

@Directive({
    selector: '[app-i18n]',
    standalone: true,
})
export class I18nDirective implements OnInit, OnChanges, OnDestroy {
    /** Name of the language key */
    @Input({ alias: 'app-i18n', required: true }) public key: string

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Input({ required: false }) interpolationValues: any[]

    constructor(private el: ElementRef, private languageService: I18nService) {
        this.el = el
    }

    private languageSubscription: Subscription

    ngOnInit() {
        if (this.languageService.isReady) this.applyExtension()
        this.languageSubscription = this.languageService.messages
            .pipe(
                filter((v) => v !== null),
                tap(() => this.applyExtension())
            )
            .subscribe()
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['key'] || changes['interpolationValues']) this.applyExtension()
    }

    /** Replace `{$INTERPOLATION}` and ICU placeholders with values. */
    private replaceInterpolation(translationRaw: string) {
        let interpolationIndex = 0
        // Recognize extract-i18n ICU format
        const regex = new RegExp(`\\{\\$[^}]+\\}`, 'g')
        const translation = translationRaw.replace(regex, (v) => {
            // See if we recognize interpolcation tag
            switch (v) {
                case '{$START_BOLD_TEXT}':
                    return '<b>'
                case '{$CLOSE_BOLD_TEXT}':
                    return '</b>'
                case '{$START_ITALIC_TEXT}':
                    return '<i>'
                case '{$CLOSE_ITALIC_TEXT}':
                    return '</i>'
                case '{$START_UNDERLINE_TEXT}':
                    return '<u>'
                case '{$CLOSE_UNDERLINE_TEXT}':
                    return '</u>'
                case '{$START_STRONG_TEXT}':
                    return '<strong>'
                case '{$CLOSE_STRONG_TEXT}':
                    return '</strong>'
                case '{$START_EMPHASIS_TEXT}':
                    return '<em>'
                case '{$CLOSE_EMPHASIS_TEXT}':
                    return '</em>'
                case ` {$START_LINK}`:
                    throw new Error('{$START_LINK} is not supported in this context')
                case '{$CLOSE_LINK}':
                    throw new Error('{$CLOSE_LINK} is not supported in this context')
                default:
                    // Not a general tag, so it must be an interpolation tag
                    return this.interpolationValues?.[interpolationIndex++] ?? ''
            }
        })
        return translation
    }

    private applyExtension() {
        const translationRaw = this.languageService.getMessage(this.key) as string
        if (translationRaw === undefined || translationRaw === null) return

        const translation = this.replaceInterpolation(translationRaw)

        this.el.nativeElement.innerHTML = translation
    }

    ngOnDestroy() {
        this.languageSubscription.unsubscribe()
    }
}
