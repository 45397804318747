import { NgIf } from '@angular/common'
import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core'
import { FormFloatingComponent } from '../form/form-floating/form-floating.component'
import { ReactiveFormsModule } from '@angular/forms'
import { TippyDirective } from '../directives/tippy.directive'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { SharedService } from 'app/_services/shared.service'
import { InputValidationDirective } from '../directives/input-validation.directive'
import { I18nService } from 'app/_services/i18n.service'
import { firstValueFrom } from 'rxjs'

@Component({
    selector: 'form-email',
    template: `<form-floating>
        <label i18n="@@contactDetails_mailingAddress_label" app-i18n="contactDetails_mailingAddress_label">E-mail</label>
        <input
            [formControl]="form"
            class="form-control"
            type="email"
            placeholder="E-mail"
            i18n-placeholder="@@contactDetails_mailingAddress_place"
            [attr.placeholder]="i18n('contactDetails_mailingAddress_place')"
            (blur)="validateEmail()"
            data-cy="email"
            autocomplete="email"
            input-validation
            #input
        />
        <button class="btn btn-outline-secondary" tippy [tippyOptions]="{ content: 'We are checking your e-mail address' }" *ngIf="loading">
            <fa-icon [icon]="['fal', 'spinner-third']" [fixedWidth]="true" [spin]="true"></fa-icon>
        </button>
        <div class="invalid-feedback">
            <span i18n="@@contactDetails_mailingAddress_errorText" app-i18n="contactDetails_mailingAddress_errorText"
                >Graag een correct email adres opgeven</span
            >
            <span *ngIf="didYouMean">
                (<a href="javascript:void(0)" (click)="didYouMeanAccept()"> {{ didYouMean }}</a> ?)
            </span>
        </div>
    </form-floating> `,
    standalone: true,
    imports: [NgIf, FormFloatingComponent, InputValidationDirective, ReactiveFormsModule, TippyDirective, FontAwesomeModule],
})
export class FormEmailComponent implements AfterViewInit {
    @Input({ required: true }) form
    /** Whether to focus on view init */
    @Input() doFocus = false

    // Input ref
    @ViewChild('input') inputRef: ElementRef
    didYouMean
    isValid
    loading

    // Shortcut
    i18n = this.i18nService.getMessage.bind(this.i18nService)

    constructor(private sharedService: SharedService, private i18nService: I18nService) {}

    ngAfterViewInit(): void {
        if (this.doFocus) this.inputRef.nativeElement.focus()
    }

    didYouMeanAccept() {
        this.form.patchValue(this.didYouMean)
    }

    async validateEmail() {
        const emailControl = this.form
        const email = emailControl.value

        // Does not pass default validation
        if (!emailControl.valid) return

        this.loading = true
        this.didYouMean = null
        this.isValid = null

        const res = await firstValueFrom(this.sharedService.emailValidate(email)).catch((e) => ({ error: e, data: '' }))

        this.loading = false

        if (res.error) return

        this.isValid = res.data.isValid
        this.didYouMean = res.data.didYouMean

        if (this.isValid) return emailControl.setErrors()
        emailControl.setErrors({ isNotValid: true })
    }
}
