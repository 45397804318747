import { Directive, ElementRef, Input, OnInit } from '@angular/core'
import tippy from 'tippy.js'

@Directive({
    selector: '[tippy]',
    standalone: true,
})
export class TippyDirective implements OnInit {
    @Input() public tippyOptions: Record<string, unknown>

    constructor(private el: ElementRef) {}

    public ngOnInit() {
        tippy(this.el.nativeElement, this.tippyOptions || {})
    }
}
