<!-- TODO: Make this more beautiful -->
<div class="form-group row" *ngIf="mobile === false && type === 'inline'">
    <!-- Month selector -->
    <div class="btn-group d-flex w-100">
        <a
            class="btn btn-link w-25"
            [class.disabled]="d.calendar.view.month.previous ? null : true"
            (click)="onCalendarViewChange('month', -1)"
        >
            <fa-icon [icon]="['fal', 'caret-left']" [fixedWidth]="true"></fa-icon>
        </a>
        <a class="btn btn-link w-100" (click)="onCalendarViewChange('month', 0)">
            {{ this.d.calendar.view.label }}
        </a>
        <a class="btn btn-link w-25" [class.disabled]="d.calendar.view.month.next ? null : true" (click)="onCalendarViewChange('month', 1)">
            <fa-icon [icon]="['fal', 'caret-right']" [fixedWidth]="true"></fa-icon>
        </a>
    </div>

    <!-- Date selector -->
    <div class="day-holder">
        <div class="day-holder-line">
            <div *ngFor="let d of d.daysOfWeek" [class.weekend]="d.weekend" class="day day-of-week">
                <div class="number">{{ d.name.full }}</div>
            </div>
        </div>
        <div class="day-holder-line" *ngFor="let week of d.calendar.days">
            <div
                class="day"
                *ngFor="let day of week; let i = index"
                [ngClass]="{
                    'month-previous': day['month-previous'],
                    'month-current': day['month-current'],
                    'month-next': day['month-next'],
                    'date-disabled': day['date-disabled'],
                    'date-range-before': day['date-range-before'],
                    'date-range-after': day['date-range-after'],
                    'date-discount-low': day['date-discount-low'],
                    'date-discount-medium': day['date-discount-medium'],
                    'date-discount-high': day['date-discount-high'],
                    'date-range-highlight': day['date-range-highlight'],
                    'date-range-highlight-start': day['date-range-highlight-start'],
                    'date-range-highlight-end': day['date-range-highlight-end'],
                    past: day.past,
                    today: day.today,
                    selected: day.selected,
                    weekend: i > 4
                }"
                (click)="onCalendarDateChange(day)"
            >
                <div class="number">
                    {{ day.label }}
                    <div class="select-indicator"></div>
                </div>
                <div class="discount" *ngIf="day['date-discount-active']">
                    {{ -day['date-discount'] | percent }}
                </div>
            </div>
        </div>
    </div>

    <!-- Extra information -->
    <div class="col text-center p-2 ps-3 pe-3 text-muted" *ngIf="dateConfig">
        <div>
            <strong>
                {{ language === 'nl' ? 'KIES UW KORTING!' : 'CHOOSE YOUR DISCOUNT!' }}
            </strong>
        </div>
        <small>
            {{
                language === 'nl'
                    ? 'Het percentage onder de datum is de korting die u krijgt.'
                    : "The percentage shown below the date is the amount of discount you'll receive."
            }}
        </small>
    </div>
</div>

<!-- Datepicker dropdown -->
<div class="form-group row" *ngIf="mobile === false && type === 'dropdown'">
    <!-- Description of field -->
    <label class="col-md-4 col-form-label" *ngIf="dateDescription && dateDescription.length">
        {{ dateDescription }}
    </label>

    <!-- Right -->
    <div [attr.class]="dateDescription ? 'col-md-8' : 'col-12'">
        <!-- Input group -->
        <div class="input-group">
            <!-- Day -->
            <select class="form-select" (change)="onInputValue('date', $event.target)">
                <option
                    *ngFor="let day of d.days"
                    [attr.selected]="d.selected.date === day.value ? true : null"
                    [attr.hidden]="day.show ? null : true"
                    [value]="day.value"
                >
                    {{ day.label }}
                </option>
            </select>

            <span class="input-group-addon"></span>

            <!-- Month -->
            <select class="form-select" (change)="onInputValue('month', $event.target)">
                <option
                    *ngFor="let month of d.months"
                    [attr.selected]="d.selected.month === month.value ? true : null"
                    [attr.hidden]="month.show ? null : true"
                    [value]="month.value"
                >
                    {{ month.label }}
                </option>
            </select>

            <span class="input-group-addon"></span>

            <!-- Year -->
            <select class="form-select" (change)="onInputValue('year', $event.target)">
                <option
                    *ngFor="let year of d.years"
                    [attr.selected]="d.selected.year === year.value ? true : null"
                    [attr.hidden]="year.show ? null : true"
                    [value]="year.value"
                >
                    {{ year.label }}
                </option>
            </select>

            <!-- Icon -->
            <span class="input-group-text">
                <fa-icon [icon]="['fal', 'calendar']" [fixedWidth]="true"></fa-icon>
            </span>
        </div>
    </div>
</div>

<!-- Datepicker mobile -->
<div class="form-group row" *ngIf="mobile === true">
    <!-- Description of field -->
    <label class="col-md-4 col-form-label" *ngIf="dateDescription && dateDescription.length">
        {{ dateDescription }}
    </label>

    <!-- Right -->
    <div [attr.class]="dateDescription ? 'col-md-8' : 'col-12'">
        <!-- Input group -->
        <div class="input-group">
            <!-- Mobile datepicker -->
            <input
                type="date"
                class="form-control"
                (change)="onInputValue('YYYY-MM-DD', $event.target)"
                [attr.placeholder]="datePlaceholder"
                [attr.value]="d.moment ? d.moment.format('YYYY-MM-DD') : ''"
                [attr.min]="d.calendar.view.from.format('YYYY-MM-DD')"
                [attr.max]="d.calendar.view.to.format('YYYY-MM-DD')"
            />

            <!-- Calender logo -->
            <span class="input-group-text" *ngIf="time">
                <fa-icon [icon]="['fal', 'calendar']" [fixedWidth]="true"></fa-icon>
            </span>
        </div>
    </div>
</div>

<!-- Time picker -->
<div class="mt-3" *ngIf="time">
    <form-floating>
        <select class="form-select" (change)="onInputValue('HH:mm', $event.target)">
            <option
                *ngFor="let time of d.times"
                [attr.selected]="d.selected.time === time.value ? true : null"
                [attr.hidden]="time.show ? null : true"
                [value]="time.value"
            >
                {{ time.label }}
            </option>
        </select>
        <label>{{ timeDescription }}</label>
        <span class="input-group-text"><fa-icon [icon]="['fal', 'clock']" [fixedWidth]="true"></fa-icon></span>
    </form-floating>
</div>

<!-- Warnings -->
<div class="form-group row" *ngIf="!!dateWarningLength">
    <div class="col-md-4"></div>
    <div class="col-md-8">
        <ng-container *ngFor="let warning of dateWarning">
            <div class="alert alert-{{ warning.style }}" *ngIf="warning.show">
                <h5 class="alert-heading">{{ warning.title }}</h5>
                <p class="mb-0">{{ warning.message }}</p>
            </div>
        </ng-container>
    </div>
</div>
