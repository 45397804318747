import { DOCUMENT } from '@angular/common'
import { Inject, Injectable } from '@angular/core'
import { ActivatedRoute } from '@angular/router'

@Injectable({
    providedIn: 'root',
})
export class GoogleAnalyticsService {
    eventPrefix = ''

    constructor(@Inject(DOCUMENT) private document, private activatedRoute: ActivatedRoute) {}

    gtmEmit(eventBase: string, transactionId: string, language: string, extras = {}) {
        const event = this.eventPrefix ? `${this.eventPrefix}-${eventBase}` : eventBase
        try {
            window['dataLayer'] = window['dataLayer'] || []
            window['dataLayer'].push({
                event,
                transactionId,
                language,
                ...extras,
            })
        } catch (ex) {
            console.error(ex)
            console.error(`Could not send GTM event`, extras)
        }
    }

    addCustomerData(orderwork, extras) {
        const customer = orderwork.customer
        extras['email'] = customer.email[0].value
        extras['phone_number'] = customer.mobile[0].value
    }

    submit(event, orderwork, language) {
        const extras = {}
        this.addCustomerData(orderwork, extras)
        this.gtmEmit(event, orderwork.identifier, language, extras)
    }

    submitRemoval(orderwork, language) {
        const transactionProducts = orderwork.estimate.calculation.products
            .filter((p) => p.exactOnlineId)
            .map((p) => ({
                sku: p.exactOnlineId,
                name: p.productRef.product.name,
                category: `Services`,
                price: p.price.euro,
                cost: p.cost.euro,
                quantity: p.count.number * (p.productRef.product.priceIsHourly ? p.duration.hours : 1),
            }))

        const transactionTotal = orderwork.estimate.calculation.priceTotal.euro
        const transactionTax = orderwork.estimate.calculation.taxTotal.euro
        const transactionCost = orderwork.estimate.calculation.costTotal.euro
        const transactionMargin = transactionTotal - transactionTax - transactionCost
        const extras = {
            transactionAffiliation: orderwork.meta ? orderwork.meta.affiliate : 'affiliate-not-provided',
            transactionTotal: ((transactionTotal - transactionTax) * 100) / 100,
            transactionTax: (transactionTax * 100) / 100,
            transactionMargin: (transactionMargin * 100) / 100,
            transactionShipping: 0,
            transactionProducts: transactionProducts,
        }
        this.addCustomerData(orderwork, extras)
        this.gtmEmit('transaction', orderwork.identifier, language, extras)
    }

    public init(eventPrefix = '') {
        this.eventPrefix = eventPrefix
        const queryParams = this.activatedRoute.snapshot.queryParams
        const gtmContainerId = queryParams['gtmContainerId']

        // Init GTM
        // console.log(`Start gtm with`, { queryParams, gtmContainerId })
        if (gtmContainerId) this.startGTM(gtmContainerId)
        // else return console.log(`Did not start GTM, container identifier was not provided`)
    }

    private startGTM(containerId) {
        try {
            const head = this.document.getElementsByTagName('head')[0]
            const body = this.document.getElementsByTagName('body')[0]

            const s = this.document.createElement('script')
            s.type = 'text/javascript'
            s.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src= 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f); })(window,document,'script','dataLayer','${containerId}');`
            head.appendChild(s)

            const n = this.document.createElement('noscript')
            n.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${containerId}" height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>`
            body.appendChild(n)
        } catch (ex) {
            console.error(ex)
            console.log('Could not init GTM')
        }
    }
}
