import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'
import { RecaptchaModule } from 'ng-recaptcha'
import { DatePickerComponent } from './date-picker/date-picker.component'
import { InputValidationDirective } from './directives/input-validation.directive'
import { TippyDirective } from './directives/tippy.directive'
import { AppFontawesomeModule } from './fontawesome.module'
import { FormAddressComponent } from './form-address/form-address.component'
import { FormCustomerComponent } from './form-customer/form-customer.component'
import { FormFloatingComponent } from './form/form-floating/form-floating.component'
import { FormGroupHorizontalComponent } from './form/form-group-horizontal/form-group-horizontal.component'
import { InformationIconComponent } from './information-icon/information-icon.component'
import { AddressPipe } from './pipes/address.pipe'
import { TimepickerComponent } from './timepicker/timepicker.component'
import { WlsNoticeComponent } from './wls-notice.component'
import { BsDropdownModule } from 'ngx-bootstrap/dropdown'
import { StepperComponent } from './stepper/stepper.component'
import { ProgressbarModule } from 'ngx-bootstrap/progressbar'
import { FormAddressesComponent } from './form-addresses/form-addresses.component'
import { ModalModule } from 'ngx-bootstrap/modal'
import { FormDateComponent } from './form-date/form-date.component'
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker'
import { enGbLocale, nlLocale } from 'ngx-bootstrap/locale'
import { defineLocale } from 'ngx-bootstrap/chronos'

defineLocale('nl', nlLocale)
defineLocale('en', enGbLocale)

@NgModule({
    imports: [
        CommonModule,
        AppFontawesomeModule,
        FormsModule,
        ReactiveFormsModule,
        BsDropdownModule.forRoot(),
        ModalModule.forRoot(),
        ProgressbarModule.forRoot(),
        BsDatepickerModule.forRoot(),
        FormCustomerComponent,
        InformationIconComponent,
        DatePickerComponent,
        FormAddressComponent,
        FormAddressesComponent,
        FormGroupHorizontalComponent,
        FormFloatingComponent,
        FormDateComponent,
        TimepickerComponent,
        WlsNoticeComponent,
        StepperComponent,
        // pipes
        AddressPipe,
        // directives
        InputValidationDirective,
        TippyDirective,
    ],
    exports: [
        CommonModule,
        AppFontawesomeModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        BsDropdownModule,
        FormCustomerComponent,
        InformationIconComponent,
        RecaptchaModule,
        DatePickerComponent,
        FormAddressComponent,
        FormAddressesComponent,
        FormGroupHorizontalComponent,
        FormFloatingComponent,
        FormDateComponent,
        TimepickerComponent,
        WlsNoticeComponent,
        StepperComponent,
        // pipes
        AddressPipe,
        // directives
        InputValidationDirective,
        TippyDirective,
    ],
})
export class SharedModule {}
