const categoryObject = {
    livingRoom: {
        en: 'Living room',
        nl: 'Woonkamer',
        comment: { en: '', nl: '' },
    },
    bedRoom: {
        en: 'Bedroom',
        nl: 'Slaapkamer',
        comment: { en: '', nl: '' },
    },
    attic: {
        en: 'Attic',
        nl: 'Zolder',
        comment: { en: '', nl: '' },
    },
    shed: {
        en: 'Shed',
        nl: 'Schuur',
        comment: { en: '', nl: '' },
    },
    study: {
        en: 'The study',
        nl: 'Studeerkamer',
        comment: { en: '', nl: '' },
    },
    kitchen: {
        en: 'Kitchen',
        nl: 'Keuken',
        comment: { en: '', nl: '' },
    },
    bathRoom: {
        en: 'Bathroom',
        nl: 'Badkamer',
        comment: { en: '', nl: '' },
    },
    garden: {
        en: 'Garden',
        nl: 'Tuin',
        comment: { en: '', nl: '' },
    },
    other: {
        en: 'Other',
        nl: 'Anders',
        comment: {
            en: 'Please specify what these objects are in the comment fields.',
            nl: 'Specificeer alstublieft wat deze diversen zijn in het opmerkingen veld "speciale objecten". ',
        },
    },
}

const m3Object = {
    twoPersonSofa: { category: 'livingRoom', en: '2 person sofa', nl: '2-zitsbank', m3: 1, id: 'twoPersonSofa' },
    threePersonSofa: { category: 'livingRoom', en: '3 person sofa', nl: '3-zitsbank', m3: 1.5, id: 'threePersonSofa' },
    televisionLivingRoom: { category: 'livingRoom', en: 'TV', nl: 'TV', m3: 0.4, id: 'televisionLivingRoom' },
    smallFurniture: { category: 'livingRoom', en: 'Small furniture', nl: 'Meubel klein', m3: 0.3, id: 'smallFurniture' },
    sideTable: { category: 'livingRoom', en: 'Side table', nl: 'Bijzettafel', m3: 0.2, id: 'sideTable' },
    secretary: { category: 'livingRoom', en: 'Secretaire', nl: 'Secretaire', m3: 0.5, id: 'secretary' },
    plants: { category: 'livingRoom', en: 'Plants', nl: 'Planten', m3: 0.1, id: 'plants' },
    paintings: { category: 'livingRoom', en: 'Paintings', nl: 'Schilderijen', m3: 0.15, id: 'paintings' },
    largeChair: { category: 'livingRoom', en: 'Large Chair', nl: 'Armstoel', m3: 0.5, id: 'largeChair' },
    dressoir: { category: 'livingRoom', en: 'Dressoir', nl: 'Dressoir', m3: 1, id: 'dressoir' },
    diningTable: { category: 'livingRoom', en: 'Dining table', nl: 'Eettafel', m3: 0.75, id: 'diningTable' },
    deskLivingRoom: { category: 'livingRoom', en: 'Desk', nl: 'Bureau', m3: 1, id: 'deskLivingRoom' },
    cupboard: { category: 'livingRoom', en: 'Cupboard', nl: 'Wandmeubel', m3: 1, id: 'cupboard' },
    coffeeTable: { category: 'livingRoom', en: 'Coffee table', nl: 'Salontafel', m3: 0.4, id: 'coffeeTable' },
    closetLivingRoom: { category: 'livingRoom', en: 'Closet', nl: 'Kast', m3: 1, id: 'closetLivingRoom' },
    chairLivingRoom: { category: 'livingRoom', en: 'Chair', nl: 'Stoel', m3: 0.2, id: 'chairLivingRoom' },
    carpet: { category: 'livingRoom', en: 'Carpet', nl: 'Vloerkleed', m3: 0.5, id: 'carpet' },
    boxesLivingRoom: { category: 'livingRoom', en: 'Boxes', nl: 'Dozen', m3: 0.1, id: 'boxesLivingRoom' },
    armChair: { category: 'livingRoom', en: 'Armchair', nl: 'Fauteuil', m3: 0.5, id: 'armChair' },

    televisionbedRoom: { category: 'bedRoom', en: 'TV', nl: 'TV', m3: 0.4, id: 'televisionbedRoom' },
    stereo: { category: 'bedRoom', en: 'Stereo', nl: 'Stereo', m3: 0.2, id: 'stereo' },
    smallWardrobe: { category: 'bedRoom', en: 'Small wardrobe', nl: 'Garderobe klein', m3: 0.4, id: 'smallWardrobe' },
    singleBed: { category: 'bedRoom', en: 'Single bed', nl: '1-persoons bed', m3: 0.3, id: 'singleBed' },
    matress: { category: 'bedRoom', en: 'Mattress', nl: 'Matras', m3: 0.1, id: 'matress' },
    largeToys: { category: 'bedRoom', en: 'Large toys ', nl: 'Groot speelgoed ', m3: 0.5, id: 'largeToys' },
    highchair: { category: 'bedRoom', en: 'Highchair (baby)', nl: 'Kinderstoel', m3: 0.15, id: 'highchair' },
    dressingTable: { category: 'bedRoom', en: 'Dressing table', nl: 'Kaptafel', m3: 0.6, id: 'dressingTable' },
    dresser: { category: 'bedRoom', en: 'Dresser', nl: 'Commode', m3: 1, id: 'dresser' },
    doubleBed: { category: 'bedRoom', en: 'Double bed', nl: '2-persoons bed', m3: 0.7, id: 'doubleBed' },
    deskbedRoom: { category: 'bedRoom', en: 'Desk', nl: 'Bureau', m3: 1, id: 'deskbedRoom' },
    cupboardTwoDoors: { category: 'bedRoom', en: 'Cupboard 2 doors', nl: 'Linnenkast 2-deurs', m3: 1, id: 'cupboardTwoDoors' },
    cupboardThreeDoors: { category: 'bedRoom', en: 'Cupboard 3 doors', nl: 'Linnenkast 3-deurs', m3: 2, id: 'cupboardThreeDoors' },
    crib: { category: 'bedRoom', en: 'Crib', nl: 'Ledikant', m3: 0.4, id: 'crib' },
    conversion: { category: 'bedRoom', en: 'Conversion', nl: 'Ombouw', m3: 0.5, id: 'conversion' },
    chairbedRoom: { category: 'bedRoom', en: 'Chair', nl: 'Stoel', m3: 0.2, id: 'chairbedRoom' },
    boxesbedRoom: { category: 'bedRoom', en: 'Boxes', nl: 'Dozen', m3: 0.1, id: 'boxesbedRoom' },
    box: { category: 'bedRoom', en: 'Box', nl: 'Box', m3: 0.2, id: 'box' },
    bigWardrobe: { category: 'bedRoom', en: 'Large wardrobe', nl: 'Garderobe groot', m3: 0.6, id: 'bigWardrobe' },
    bedside: { category: 'bedRoom', en: 'Bedside cabinet', nl: 'Nachtkast', m3: 0.3, id: 'bedside' },
    babyBath: { category: 'bedRoom', en: 'Baby bath', nl: 'Babybad', m3: 0.2, id: 'babyBath' },

    washingMachine: { category: 'bathRoom', en: 'Washing machine', nl: 'Wasmachine', m3: 0.6, id: 'washingMachine' },
    dryer: { category: 'bathRoom', en: 'Dryer', nl: 'Droger', m3: 0.6, id: 'dryer' },
    closetBathRoom: { category: 'bathRoom', en: 'Closet', nl: 'Kast', m3: 1, id: 'closetBathRoom' },
    boxesBathRoom: { category: 'bathRoom', en: 'Boxes', nl: 'Dozen', m3: 0.1, id: 'boxesBathRoom' },

    deskStudy: { category: 'study', en: 'Desk', nl: 'Bureau', m3: 1, id: 'deskStudy' },
    computer: { category: 'study', en: 'Computer', nl: 'Computer', m3: 0.25, id: 'computer' },
    chairStudy: { category: 'study', en: 'Chair', nl: 'Stoel', m3: 0.2, id: 'chairStudy' },
    boxesStudy: { category: 'study', en: 'Boxes', nl: 'Dozen', m3: 0.1, id: 'boxesStudy' },
    bookcase: { category: 'study', en: 'Bookcase', nl: 'Boekenkast', m3: 1, id: 'bookcase' },

    stove: { category: 'kitchen', en: 'Stove', nl: 'Fornuis', m3: 0.5, id: 'stove' },
    refrigerator: { category: 'kitchen', en: 'Refridgerator', nl: 'Koelkast', m3: 0.5, id: 'refrigerator' },
    oven: { category: 'kitchen', en: 'Oven', nl: 'Oven', m3: 0.3, id: 'oven' },
    microwave: { category: 'kitchen', en: 'Microwave', nl: 'Magnetron', m3: 0.3, id: 'microwave' },
    freezer: { category: 'kitchen', en: 'Freezer', nl: 'Vriezer', m3: 0.5, id: 'freezer' },
    dishwasher: { category: 'kitchen', en: 'Dishwasher', nl: 'Afwasmachine', m3: 0.5, id: 'dishwasher' },
    dinette: { category: 'kitchen', en: 'Dinette', nl: 'Eethoek', m3: 1, id: 'dinette' },
    closetKitchen: { category: 'kitchen', en: 'Closet', nl: 'Kast', m3: 1, id: 'closetKitchen' },
    buffet: { category: 'kitchen', en: 'Buffet', nl: 'Buffetkast', m3: 1, id: 'buffet' },
    boxesKitchen: { category: 'kitchen', en: 'Boxes', nl: 'Dozen', m3: 0.1, id: 'boxesKitchen' },

    workbench: { category: 'shed', en: 'Workbench', nl: 'Werkbank', m3: 1, id: 'workbench' },
    smallBike: { category: 'shed', en: 'Small bike', nl: 'Fiets klein', m3: 0.3, id: 'smallBike' },
    ladder: { category: 'shed', en: 'Ladder', nl: 'Ladder', m3: 0.3, id: 'ladder' },
    gardenTools: { category: 'shed', en: 'Garden tools', nl: 'Tuingereedschap', m3: 0.5, id: 'gardenTools' },
    boxesShed: { category: 'shed', en: 'Boxes', nl: 'Dozen', m3: 0.1, id: 'boxesShed' },
    bigBike: { category: 'shed', en: 'Large bike', nl: 'Fiets groot', m3: 0.5, id: 'bigBike' },

    wood: { category: 'garden', en: 'Wood', nl: 'Hout', m3: 0.1, id: 'wood' },
    planter: { category: 'garden', en: 'Planter', nl: 'Plantenbak', m3: 0.5, id: 'planter' },
    gardenTables: { category: 'garden', en: 'Garden tables', nl: 'Tuintafels', m3: 0.75, id: 'gardenTables' },

    cradle: { category: 'attic', en: 'Cradle', nl: 'Wieg', m3: 0.5, id: 'cradle' },
    closetAttic: { category: 'attic', en: 'Closet', nl: 'Kast', m3: 1, id: 'closetAttic' },
    christmasDecorations: { category: 'attic', en: 'Christmas decorations', nl: 'Kerstspullen', m3: 0.5, id: 'christmasDecorations' },
    campingGear: { category: 'attic', en: 'Camping gear', nl: 'Kampeerspullen', m3: 1, id: 'campingGear' },
    boxesAttic: { category: 'attic', en: 'Boxes', nl: 'Dozen', m3: 0.1, id: 'boxesAttic' },
    bed: { category: 'attic', en: 'Bed', nl: 'Bed', m3: 0.3, id: 'bed' },

    otherOne: { category: 'other', en: 'Other 1.0m3', nl: 'Diversen 1.0m3', m3: 1, id: 'otherOne' },
    otherHalf: { category: 'other', en: 'Other 0.5m3', nl: 'Diversen 0.5m3', m3: 0.5, id: 'otherHalf' },
}

// Sorting
// let sorted = {};
// Object.keys(x)
// .map(function(id) { let obj = x[id]; obj.id = id; return obj;  })
// .sort(function(a,b) {
//     if(a.category < b.category) return -1;
//     if(a.category > b.category) return 1;
// 	if(a.id < b.id) return -1;
// 	if(a.id > b.id) return 1;
//     return 0;

// 	return (a.category > b.category)
// })
// .forEach(function(obj) { sorted[obj.id]=obj; })

function all(language, order) {
    let m3Array = [] || [
        {
            categoryTitle: 'Woonkamer',
            categoryName: 'livingRoom',
            categoryComment: 'Houd ook rekening met rare dingen in uw woonkamer!',
            categoryTotal: null,
            objects: [
                {
                    objectTitle: 'Kast',
                    objectName: 'closet', // now I am in the closet toooooo...
                    objectM3: 0,
                },
            ],
        },
    ]

    // Loop over all existing objects
    Object.keys(m3Object).forEach((key) => {
        let obj = m3Object[key]

        // Find category
        let cat = m3Array.find((arr) => arr.categoryName == obj.category)
        if (!cat) {
            cat = {
                categoryName: obj.category,
                categoryTitle: categoryObject[obj.category][language],
                categoryComment: categoryObject[obj.category]['comment'][language],
                categoryTotal: null,
                objects: [],
            }
            m3Array.push(cat)
        }

        // Modify object
        obj = {
            objectTitle: obj[language],
            objectName: key,
            m3: obj.m3,
            value:
                order &&
                order.estimate &&
                order.estimate.m3Calculator &&
                order.estimate.m3Calculator[obj.category] &&
                order.estimate.m3Calculator[obj.category][key]
                    ? order.estimate.m3Calculator[obj.category][key]
                    : null,
        }

        cat.categoryTotal = Math.round(((obj.value || 0) * obj.m3 + cat.categoryTotal) * 10) / 10
        cat.objects.push(obj)
    })
    for (const cat of m3Array) {
        cat.objects = cat.objects.sort((a, b) => {
            if (a.objectName.includes('boxes')) return -1
            if (b.objectName.includes('boxes')) return 1
            if (a.objectTitle < b.objectTitle) return -1
            if (a.objectTitle > b.objectTitle) return 1
            return 0
        })
    }

    return m3Array
}

module.exports = all
