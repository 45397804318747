import { DOCUMENT } from '@angular/common'
import { Inject, Injectable } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { environment } from '../../environments/environment.prod'
import { AuthService } from './auth.service'
@Injectable({ providedIn: 'root' })
export class SharedService {
    constructor(@Inject(DOCUMENT) private document, private authService: AuthService, private title: Title) {}

    titleSet(company) {
        const host = window.location.host
        let envString = ''
        if (host.startsWith('test.')) envString = 'TEST | '
        if (host.startsWith('localhost')) envString = 'LOCAL | '
        this.title.setTitle(`${envString}${company.name.full} @ ${environment.package.version}`)
    }

    faviconSet(company) {
        const favicons = [
            {
                rel: `apple-touch-icon`,
                sizes: `144x144`,
                href: `/assets/favicons-${company.name.id}/apple-touch-icon.png`,
            },
            {
                rel: `icon`,
                type: `image/png`,
                sizes: `32x32`,
                href: `/assets/favicons-${company.name.id}/favicon-32x32.png`,
            },
            {
                rel: `icon`,
                type: `image/png`,
                sizes: `16x16`,
                href: `/assets/favicons-${company.name.id}/favicon-16x16.png`,
            },
            {
                rel: `manifest`,
                href: `/assets/favicons-${company.name.id}/manifest.json`,
            },
            {
                rel: `mask-icon`,
                href: `/assets/favicons-${company.name.id}/safari-pinned-tab.svg`,
                color: `#a68100`,
            },
            {
                rel: `shortcut icon`,
                href: `/assets/favicons-${company.name.id}/favicon.ico`,
            },
        ]

        favicons.forEach((favicon, index) => {
            const elemId = `favicon_${index}`
            const elem = this.document.getElementById(elemId)

            // Check if elem exists
            if (!elem) return console.warn(`Whoops, we could not find elem with id: ${elemId}`)

            Object.keys(favicon).forEach((attr) => elem.setAttribute(attr, favicon[attr]))
        })
    }

    companyUpdate(company, bootswatch) {
        this.titleSet(company)
        this.bootswatchSet(company, bootswatch)
        this.faviconSet(company)
    }

    bootswatchSet(company = { style: { bootswatch: 'united' } }, bootswatch = null) {
        // Find element
        const elem = this.document.getElementById('css_0')
        const bw = bootswatch ? bootswatch : company.style.bootswatch
        const href = `//cdnjs.cloudflare.com/ajax/libs/bootswatch/5.3.3/${bw}/bootstrap.min.css`

        // Check if elem exists
        if (!elem) return

        elem.setAttribute(`href`, href)
        elem.setAttribute(`rel`, `stylesheet`)
    }

    geocodeAddress(address, orderworkId) {
        const url = `api/google-maps/geocode/${orderworkId}`
        const data = {
            address: { id: address.id },
            country: address.country,
            city: address.city,
            street: address.street,
            number: address.number,
            addition: address.addition,
        }
        return this.authService.post(url, data)
    }

    addressFromPostal(address) {
        const url = `api/postal/postcode/${address.postal}/${address.number}/${address.addition}`
        return this.authService.get(url)
    }

    emailValidate(email) {
        const url = `api/email/validate/${email}`
        return this.authService.get(url)
    }

    getDateConfig(companyNameId, orderworkId) {
        const url = `api/orderwork/date-config/${companyNameId}/${orderworkId}`
        return this.authService.get(url)
    }
}
