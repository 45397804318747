import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
    name: 'address',
    standalone: true,
})
export class AddressPipe implements PipeTransform {
    transform(address) {
        const addressArray = [
            `${address.street} ${address.number}${address.addition ? `-${address.addition}` : ''}`,
            address.postal,
            address.city,
        ].filter((elem) => elem.trim())
        const rule = addressArray.join(', ')
        return rule
    }
}
