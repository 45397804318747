<form [formGroup]="form" *ngIf="form">
    <div class="input-group">
        <!-- Time picker -->
        <select class="form-select" formControlName="time">
            <option *ngFor="let time of times" [attr.hidden]="time.show ? null : true" [value]="time.value">{{ time.label }}</option>
        </select>

        <!-- Clock logo -->
        <span class="input-group-text">
            <fa-icon [icon]="['fal', 'clock']" [fixedWidth]="true"></fa-icon>
        </span>
    </div>
</form>
