export function parseIafFactor(value: string | boolean, orderwork): boolean {
    if (typeof value === 'boolean') return value
    const f = new Function('orderwork', value)
    try {
        return f(orderwork)
    } catch (ex) {
        console.error(ex)
        return true
    }
}
