import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms'

export function exactMomentNotEmptyValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const isEmpty = control.value.unix === 0
        return isEmpty ? { emptyDate: { value: control.value } } : null
    }
}

const postalRegex = new RegExp('^([1-9][0-9]{3})([A-Z]{2})$')
export function postalValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        const value = control.value
        const validRegex = postalRegex.test(value)
        if (validRegex) return null
        return { format: { value } }
    }
}

const numberRegex = new RegExp('^[0-9][0-9 ]*$')
export function numberValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        const value = control.value
        const validRegex = numberRegex.test(value)
        if (validRegex) return null
        return { format: { value } }
    }
}
