import { Component, Input, ViewEncapsulation } from '@angular/core'

@Component({
    selector: 'form-group-horizontal',
    template: `
        <div class="form-group-container row mb-3">
            <div class="col-4">
                <label class="col-form-label" [class.col-form-label-sm]="size === 'sm'" [class.col-form-label-lg]="size === 'lg'">
                    <ng-content select="label"></ng-content>
                </label>
            </div>

            <div class="col-8">
                <div class="input-group" [class.input-group-sm]="size === 'sm'" [class.input-group-lg]="size === 'lg'">
                    <!-- All non matched elements also get placed here -->
                    <ng-content></ng-content>
                </div>

                <!-- Custom control -->
                <ng-content select=".custom-control"></ng-content>

                <!-- All project specific element after this -->
                <ng-content select="row"></ng-content>
                <ng-content select="fieldset"></ng-content>
                <ng-content select="upload-image"></ng-content>
                <ng-content select="upload-file"></ng-content>
                <ng-content select="timepicker"></ng-content>
                <ng-content select="datepicker"></ng-content>
                <ng-content select="ckeditor"></ng-content>
                <ng-content select="form-color-picker"></ng-content>
                <ng-content select=".card"></ng-content>
                <ng-content select=".badge"></ng-content>
                <ng-content select=".form-check"></ng-content>
                <ng-content select=".d-grid"></ng-content>

                <small class="form-text text-muted">
                    <ng-content select="[help-text]"></ng-content>
                </small>
                <small class="form-text text-danger text-right">
                    <ng-content select="[help-text-danger]"></ng-content>
                </small>
                <small class="form-text text-muted text-right">
                    <ng-content select="[help-text-muted]"></ng-content>
                </small>
            </div>
        </div>
    `,
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./form-group-horizontal.component.scss'],
    standalone: true,
})
export class FormGroupHorizontalComponent {
    @Input() size = 'md'
}
