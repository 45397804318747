import { HttpClient, HttpErrorResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, throwError } from 'rxjs'
import { catchError, tap } from 'rxjs/operators'

@Injectable({ providedIn: 'root' })
export class AuthService {
    options = {
        headers: null,
    }

    methods = {
        map: this.map.bind(this),
        // catch: this.catch.bind(this),
    }

    authData = {
        token: null,
        expired: null,
        user: null,
    }

    constructor(private httpClient: HttpClient) {}

    // Http related functions
    //
    get(url, opts = {}) {
        return <Observable<ValidResponse>>(
            this.httpClient.get(url, { ...this.getOptions(), ...opts }).pipe(tap(this.map.bind(this)), catchError(this.catch.bind(this)))
        )
    }
    post(url, data, opts = {}) {
        return <Observable<ValidResponse>>(
            this.httpClient
                .post(url, data, { ...this.getOptions(), ...opts })
                .pipe(tap(this.map.bind(this)), catchError(this.catch.bind(this)))
        )
    }
    patch(url, data, opts = {}) {
        return <Observable<ValidResponse>>(
            this.httpClient
                .patch(url, data, { ...this.getOptions(), ...opts })
                .pipe(tap(this.map.bind(this)), catchError(this.catch.bind(this)))
        )
    }
    delete(url, opts = {}) {
        return <Observable<ValidResponse>>(
            this.httpClient.delete(url, { ...this.getOptions(), ...opts }).pipe(tap(this.map.bind(this)), catchError(this.catch.bind(this)))
        )
    }

    getOptions() {
        return {}
    }

    map(res: ValidResponse) {
        if (res.warning) {
            // this.toastrService.warning(res.message, res.error)
        } else if (res.error) {
            // this.toastrService.error(res.message, res.error)
            console.error('Application has crashed, we will send a report to Job & Tim')
            console.error({ res })
            throw res
        } else if (res.failure) {
            console.error('Application has crashed, we will send a report to Job & Tim')
            console.error({ res })
            throw res
        }

        return res
    }

    catch(err: HttpErrorResponse) {
        // Rethrow
        console.log(`Oh by the way, this is all we know:`, { err })
        return throwError(err)
    }
}

export interface ValidResponse {
    failure: any
    error: any
    warning: any

    data: any
    meta: any
    message: any
}
