import { Component, forwardRef, Input, OnInit } from '@angular/core'
import { ControlValueAccessor, UntypedFormBuilder, NG_VALUE_ACCESSOR, FormsModule, ReactiveFormsModule } from '@angular/forms'
import { distinctUntilChanged } from 'rxjs/operators'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { NgIf, NgFor } from '@angular/common'

@Component({
    selector: 'timepicker',
    templateUrl: './timepicker.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TimepickerComponent),
            multi: true,
        },
    ],
    standalone: true,
    imports: [NgIf, FormsModule, ReactiveFormsModule, NgFor, FontAwesomeModule],
})
export class TimepickerComponent implements ControlValueAccessor, OnInit {
    @Input() timePrecision = 15
    @Input() timeRangeFrom = 0
    @Input() timeRangeTo = 24
    form
    editable = true

    times = []

    // this is the initial value set to the component
    public writeValue(time) {
        // Create form
        this.initForm(time)

        // Listen for values in form
        this.form.valueChanges.pipe(distinctUntilChanged()).subscribe(this.doPropagateChange.bind(this))
    }

    public registerOnChange(fn: any) {
        this.propagateChange = fn
    }
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    public registerOnTouched() {}
    // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
    private propagateChange = (_: unknown) => {}

    private doPropagateChange() {
        const propagate = this.form.value.time

        // Propagate form change
        this.propagateChange(propagate)
    }

    constructor(private fb: UntypedFormBuilder) {}

    ngOnInit() {
        // Create times
        for (let i = this.timeRangeFrom; i < this.timeRangeTo; i++) {
            for (let j = 0; j < 60; j = j + this.timePrecision) {
                const label = `${i > 9 ? '' : '0'}${i}:${j > 9 ? '' : '0'}${j}`
                this.times.push({
                    show: true,
                    label: label,
                    value: label,
                })
            }
        }
    }

    initForm(time) {
        this.form = this.fb.group({
            time: time,
        })
    }
}
