import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms'
import m3 from 'app/../../server/data/m3Objects'
import { exactMomentNotEmptyValidator, numberValidator, postalValidator } from './validators'

export const getM3Object = (languageId, orderwork) => m3(languageId, orderwork)

function createM3CategoryGroup(orderwork, categoryName, objects) {
    const group = { total: 0 }
    objects.forEach((object) => {
        group[object.objectName] = [orderwork.estimate.m3Calculator ? orderwork.estimate.m3Calculator[categoryName][object.objectName] : 0]
    })
    return group
}

function createM3CategoryGroupHolder(language, fb, orderwork) {
    const formGroup = { total: [orderwork.estimate.m3Calculator ? orderwork.estimate.m3Calculator.total : 0] }
    const m3Object = m3(language, orderwork)
    m3Object.forEach((category) => {
        formGroup[category.categoryName] = fb.group(createM3CategoryGroup(orderwork, category.categoryName, category.objects))
    })
    return formGroup
}

export function createAddressFormGroup(fb, address) {
    const postalValidators = [Validators.required, postalValidator()]
    const numberValidators = [Validators.required, numberValidator()]
    const isCountryNL = address.country === 'nl'
    const formGroup = fb.group({
        country: [address.country, Validators.required],
        street: [address.street, isCountryNL ? Validators.required : null],
        number: [address.number, isCountryNL ? numberValidators : null],
        addition: [address.addition],
        city: [address.city, Validators.required],
        postal: [address.postal, isCountryNL ? postalValidators : null],
        latitude: [address.latitude],
        longitude: [address.longitude],
        description: [address.description],
        floor: [address.floor],
        locationType: [address.locationType],
        moveType: [address.moveType],
        liftInternal: [address.liftInternal],
        liftExternal: [address.liftExternal],
        distanceToDoor: [address.distanceToDoor],
    })
    formGroup.get('country').valueChanges.subscribe((country) => {
        if (country === 'nl') {
            console.log('Adding validators')
            formGroup.get('street').addValidators([Validators.required])
            formGroup.get('street').updateValueAndValidity({ onlySelf: true, emitEvent: false })
            formGroup.get('number').addValidators(numberValidators)
            formGroup.get('number').updateValueAndValidity({ onlySelf: true, emitEvent: false })
            formGroup.get('postal').addValidators(postalValidators)
            formGroup.get('postal').updateValueAndValidity({ onlySelf: true, emitEvent: false })
        } else {
            console.log('Clearing validators')
            formGroup.get('street').clearValidators()
            formGroup.get('street').updateValueAndValidity({ onlySelf: true, emitEvent: false })
            formGroup.get('number').clearValidators()
            formGroup.get('number').updateValueAndValidity({ onlySelf: true, emitEvent: false })
            formGroup.get('postal').clearValidators()
            formGroup.get('postal').updateValueAndValidity({ onlySelf: true, emitEvent: false })
        }
    })
    return formGroup
}

export function createProductFormGroup(fb, product) {
    return fb.group({
        discount: [product.discount || 0],
        addition: [product.addition || 0],
        taxCode: [product.taxCode],
        taxPercentage: [product.taxPercentage],
        productRef: fb.group({
            product: [product.productRef?.product],
            productId: [product.productRef?.productId],
        }),
        description: fb.group({
            nl: [product.description?.nl || ''],
            en: [product.description?.en || ''],
        }),
        count: fb.group({
            number: [product.count?.number || 0],
        }),
        duration: fb.group({
            hours: [product.duration?.hours || 0],
        }),
        cost: fb.group({
            euro: [product.cost?.euro || 0],
        }),
        costTotal: fb.group({
            euro: [product.costTotal?.euro || 0],
        }),
        price: fb.group({ euro: [product.price?.euro || 0] }),
        priceTotal: fb.group({
            euro: [product.priceTotal?.euro || 0],
        }),
        deposit: fb.group({
            euro: [product.deposit?.euro || 0],
        }),
        depositTotal: fb.group({
            euro: [product.depositTotal?.euro || 0],
        }),
    })
}

export function createOrderworkForm(languageId, fb: UntypedFormBuilder, orderwork, stepActive): UntypedFormGroup {
    const { estimate } = orderwork
    return fb.group({
        _id: [orderwork._id],
        identifier: [orderwork.identifier],

        customer: orderwork.customer,

        terms: [false, Validators.requiredTrue],
        initMail: [true],
        orderType: orderwork.orderType,
        orderLabel: orderwork.orderLabel,
        companyRef: orderwork.companyRef,

        captcha: fb.group({
            success: [orderwork.captcha.success],
            value: [orderwork.captcha.value],
        }),

        invoice: [orderwork.invoice || '1'],
        invoiceAddress: orderwork.invoiceAddress,
        international: orderwork.international,

        stepActive,
        stepHistory: [orderwork.stepHistory || []],

        discount: fb.group({
            code: [orderwork.discount ? orderwork.discount.code : ''],
            valid: [orderwork.discount ? orderwork.discount.valid : false],
        }),

        estimate: fb.group({
            calculation: fb.group({
                products: fb.array(orderwork.estimate?.calculation.products.map((prod) => createProductFormGroup(fb, prod))),
                durationRemoval: fb.group({
                    hours: [estimate.calculation.durationRemoval?.hours, Validators.required],
                }),
                costTotal: estimate.calculation.costTotal,
                taxTotal: fb.group({
                    euro: estimate.calculation.taxTotal ? estimate.calculation.taxTotal.euro : 0,
                }),
                depositTotal: fb.group({
                    euro: estimate.calculation.depositTotal ? estimate.calculation.depositTotal.euro : 0,
                }),
                priceTotal: fb.group({
                    euro: estimate.calculation.priceTotal ? estimate.calculation.priceTotal.euro : 0,
                }),
            }),

            addresses: fb.array(estimate.addresses),

            startCertainty: [estimate.startCertainty || '', Validators.required],
            startCertaintyDates: [estimate.startCertaintyDates.map((d) => new Date(d)), [Validators.required]],
            startMargin: [estimate.startMargin || { hours: 0 }],
            start: [estimate.start, exactMomentNotEmptyValidator()],

            info: fb.group({
                disassemble: [estimate.info.disassemble, Validators.required],
                specialObjects: [estimate.info.specialObjects],
                question: [estimate.info.question],

                fullService: [estimate.info.fullService],
                handyman: [estimate.info.handyman],
                movingLift: [estimate.info.movingLift],
            }),

            m2: estimate.m2,
            m3Calculator: fb.group(createM3CategoryGroupHolder(languageId, fb, orderwork)),
        }),
    })
}
