import { NgModule } from '@angular/core'
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { faWindows } from '@fortawesome/free-brands-svg-icons'
import {
    faBoxOpen,
    faCalendar,
    faCaretDown,
    faCaretLeft,
    faCaretRight,
    faCaretUp,
    faCheck,
    faCheckCircle,
    faChevronDown,
    faChevronUp,
    faCircleNotch,
    faClipboard,
    faClipboardUser,
    faClock,
    faEnvelope,
    faEnvelopeOpenText,
    faEuroSign,
    faInfoCircle,
    faMinus,
    faPersonDolly,
    faPhone,
    faPlus,
    faQuestionCircle,
    faSpinner,
    faSpinnerThird,
    faTags,
    faTimes,
    faTrash,
    faTrashAlt,
    faTruckLoading,
    faUser,
    faVenusMars,
} from '@fortawesome/pro-light-svg-icons'

@NgModule({
    imports: [FontAwesomeModule],
    declarations: [],
    exports: [FontAwesomeModule],
})
export class AppFontawesomeModule {
    constructor(private library: FaIconLibrary) {
        library.addIcons(
            faWindows,
            faVenusMars,
            faTrash,
            faCheck,
            faCircleNotch,
            faPlus,
            faTimes,
            faMinus,
            faCalendar,
            faCaretRight,
            faCaretLeft,
            faCaretUp,
            faCaretDown,
            faChevronDown,
            faChevronUp,
            faUser,
            faClock,
            faInfoCircle,
            faQuestionCircle,
            faSpinnerThird,
            faTrashAlt,
            faEuroSign,
            faTags,
            faSpinner,
            faBoxOpen,
            faClipboardUser,
            faTruckLoading,
            faPhone,
            faEnvelope,
            faCheckCircle,
            faPersonDolly,
            faEnvelopeOpenText,
            faClipboard
        )
    }
}
