import { Directive, Input } from '@angular/core'
import { AbstractControl } from '@angular/forms'

@Directive({
    selector: '[input-validation]',
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: {
        '[attr.required]': 'isRequired ? true : null',
        '[class.is-valid]': 'formControl.touched && formControl.valid',
        '[class.is-invalid]': 'formControl.touched && formControl.invalid',
    },
    standalone: true,
})
export class InputValidationDirective {
    @Input() formControl

    // Required is used to trigger BS Validation
    // https://medium.com/the-tech-masters/bootstrap-form-validation-in-angular-1b441d689145
    get isRequired() {
        if (!this.formControl.validator) return false
        const validator = this.formControl.validator(this.formControl as AbstractControl)
        return validator && validator.required
    }
}
